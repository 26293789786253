<template>

  <div class="custom-table">
    <vxe-table border show-overflow ref="xTable" :data="orderData">
      <vxe-table-column field="productCode" align="center" title="产品编码"></vxe-table-column>
      <vxe-table-column field="productLevelName" align="center" title="产品系列名称"></vxe-table-column>
      <vxe-table-column field="productName" align="center" title="产品名称"></vxe-table-column>
      <vxe-table-column field="productPrice" align="center" title="直营产品单价(瓶/盒)元"></vxe-table-column>
      <vxe-table-column field="productNum" align="center" title="数量（瓶/盒）"></vxe-table-column>
       <vxe-table-column field="productTotalAmount" align="center" title="总金额"></vxe-table-column>
    </vxe-table>
  </div>

</template>

<script>
import request from '@/found/utils/request';
import _ from 'xe-utils';

export default {
  props: {
    value: Array,
  },
  components: { },
  data() {
    return {
      orderData: [],
    };
  },
  watch: {
    value(newVal, oldVal) {
      for (const item of newVal) {
        // item.amount = _.multiply(Number(item.productPrice || 0), Number(item.productTotalAmount || 0)).toFixed(2);
        item.productPrice = Number(item.productPrice || 0).toFixed(2);
        item.productTotalAmount = Number(item.productTotalAmount || 0).toFixed(2);
      }
      this.orderData = newVal || [];
    },
  },
  created() {

  },
  methods: {

  },
};
</script>
