import TablePage from '@/found/components/table_page';
import { VXETable } from 'vxe-table';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    parentCode: String,
  },
  data() {
    return {};
  },

  async created() {
    await this.getfieldType();
    this.getConfigList('directly_order_list');
  },
  methods: {
    clickVisible({
      row,
      val: { code },
    }) {
      const { paymentStatusName, orderTypeName, approvalStatusName } = row;
      if (paymentStatusName && code === 'cancel_warehousing'
       && (paymentStatusName === '否' || paymentStatusName === '全部收款' || paymentStatusName === '未完全收款')) return false;

      if (approvalStatusName && code === 'print'
      && (approvalStatusName === '已提交' || approvalStatusName === '审核驳回' || approvalStatusName === '审批中')) return false;

      if (approvalStatusName && code === 'cancel_warehousing'
      && (approvalStatusName === '已提交' || approvalStatusName === '审核驳回' || approvalStatusName === '审批中')) return false;
      return true;
    },
    /**
     * 获取数据类型数据字典
     */
    async  getfieldType() {
      await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'sfa_invoice_type',
      }).then((res) => {
        if (res.success) {
          this.billTypeList = res.result;
        }
      });
    },
    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.formConfig = { ...val, code: val.code, row };
        this.modalConfig = {
          title: '查看',
          // showFooter: true,
        };
        this.openFull();
      }
      if (val.code === 'print') {
        this.formConfig = { ...val, code: val.code, row };
        request.post('/sfa/sfaWorkDailyController/loadEditPageForWorkbenchOrder', { bizId: row.id }).then((res) => {
          this.beginPrint(res.result);
        });
      }
      if (val.code === 'cancel_warehousing') {
        this.$confirm('是否确定取消入库?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          request.post('/sfa/sfaWorkDailyController/cancleWorkOrder', { id: row.id }).then((res) => {
            this.$message.success('取消入库成功');
            this.getList();
          });
        });
      }
      return true;
    },

    // 打印
    beginPrint(detail) {
      const orderList = detail.sfaVisitStepOrderItems || [];
      const iDetail = detail;
      iDetail.ext5Name = (this.billTypeList.find((a) => a.dictCode === iDetail.ext5) || {}).dictValue || '';

      let orderItem = '';
      let totalCount = 0;
      orderList.forEach((item, index) => {
        totalCount += _.multiply(Number(item.quantity || 0), Number(item.price || 0));
        orderItem += `
          <tr>
            <td colspan="1">${index + 1}</td>
            <td colspan="2">${item.productName || ''}</td>
            <td colspan="1">${item.unitName || ''}</td>
            <td colspan="1">${item.ext1 || ''}</td>
            <td colspan="1">${item.price || ''}</td>
            <td colspan="1">${item.quantity || ''}</td>
            <td colspan="1">${_.multiply(Number(item.quantity || 0), Number(item.price || 0)).toFixed(2) || ''}</td>
          </tr>
        `;
      });

      const RMB = this.digitUppercase(totalCount);
      const html = `<table style="width: 100%;" border="1" cellspacing="0">
                  <tr>
                    <td colspan="1">订单号</td>
                    <td colspan="2">${detail.orderCode || ''}</td>
                    <td colspan="1">客户</td>
                    <td colspan="1">${detail.clientName || ''}</td>
                    <td colspan="1">业务员</td>
                    <td colspan="2">${detail.createName || ''}</td>
                  </tr>
                  <tr>
                    <td colspan="1">收货人</td>
                    <td colspan="2">${detail.contacts || ''}</td>
                    <td colspan="1">期望收货时间</td>
                    <td colspan="1">${detail.expectReceive || ''}</td>
                    <td colspan="1">收货人电话</td>
                    <td colspan="2">${detail.telephone || ''}</td>
                  </tr>

                  <tr>
                    <td colspan="1">收货地址</td>
                    <td colspan="7">${detail.address || ''}</td>
                  </tr>
                  <tr>
                    <td colspan="8">商品信息</td>
                  </tr>
                  <tr>
                    <td colspan="1">序号</td>
                    <td colspan="2">商品名称</td>
                    <td colspan="1">单位</td>
                    <td colspan="1">规格</td>
                    <td colspan="1">单价(元)</td>
                    <td colspan="1">数量</td>
                    <td colspan="1">金额(元)</td>
                  </tr>
                  ${orderItem}
                  <tr>
                     <td colspan="1">合计金额(元)</td>
                     <td colspan="3">${totalCount.toFixed(2) || 0}</td>
                     <td colspan="1">合计金额大写(元)</td>
                     <td colspan="3">${RMB || ''}</td>
                  </tr>
                  <tr>
                      <td colspan="1">送货人签字</td>
                      <td colspan="3"></td>
                      <td colspan="1">收货人签字</td>
                      <td colspan="3"></td>
                  </tr>
                  <tr>
                    <td colspan="1">开票类型</td>
                    <td colspan="3">${detail.ext5Name || ''}</td>
                    <td colspan="1">收款方式</td>
                    <td colspan="3"></td>
                  </tr>
                  <tr>
                    <td colspan="1">备注</td>
                    <td colspan="7">${detail.remarks || ''}</td>
                  </tr>
              </table>`;

      VXETable.print({
        sheetName: '直营业务出库单',
        style: ` table {
                width:  100%;
                margin-bottom: 10px;
                table-layout:fixed;
                word-break:break-all;
                min-height: 25px;
                line-height: 25px;
                border-collapse: collapse;
                padding:2px;
              }
              td {
                text-align: center;
                line-height: 22px;
              }
              th {
                font-weight: bold;
              }
              p{

                padding: 0;
                margin:0
              }
              @media   print   {
                body   {   font-size: 8pt; line-height: 1.2}
                }
              `,
        content: html,
      });
    },
    // 人民币转义
    /* eslint-disable */
    digitUppercase(n) {
      const fraction = ['角', '分'];
      const digit = [
        '零', '壹', '贰', '叁', '肆',
        '伍', '陆', '柒', '捌', '玖',
      ];
      const unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟'],
      ];
      const IsNum = Number(n);
      if (!isNaN(IsNum)) {
        const head = n < 0 ? '欠' : '';
        n = Math.abs(n);
        let s = '';
        for (var i = 0; i < fraction.length; i++) {
          s += (digit[Math.floor(n * 100 / 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
        }
        s = s || '整';
        n = Math.floor(n);
        for (var i = 0; i < unit[0].length && n > 0; i++) {
          let p = '';
          for (let j = 0; j < unit[1].length && n > 0; j++) {
            p = digit[n % 10] + unit[1][j] + p;
            n = Math.floor(n / 10);
          }
          s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
        }
        return head + s.replace(/(零.)*零元/, '元')
          .replace(/(零.)+/g, '零')
          .replace(/^整$/, '零元整');
      }

      return '';
    },
  },
};
