import TablePage from '@/found/components/table_page';
import { VXETable } from 'vxe-table';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import { ProcessLog } from '../../../../../modules';
import Form from './form';

export default {
  // name: '出货任务报表',
  extends: TablePage,
  components: {
    Form,
    ProcessLog,
  },
  data() {
    return {

    };
  },

  created() {
    this.getConfigList('sales_statistics_list').then(() => {
      this.configs.tableConfig.idKey = 'idIndex';
    });
  },
  methods: {
    // 获取列表数据后置函数
    afterGetList() {
      this.tableData = (this.tableData || []).map((a, aIndex) => ({
        ...a,
        idIndex: `${a.id}-${aIndex}`,
      }));
      return true;
    },

    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.formConfig = { ...val, code: val.code, row };
        this.modalConfig = {
          title: '查看',
        };
        this.openFull();
      } else if (val.code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row: { processNo: row.processNo },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
      return true;
    },
  },
};
