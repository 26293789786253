import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ProductInfo from '../components/table.vue';

formCreate.component('ProductInfo', ProductInfo);
export default {
  name: 'table-component',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return { formFunctionCode: 'sales_statistics_form' };
  },
  created() {
  },
  methods: {
    async formComplete() {
      if (this.formConfig.code === 'view') {
        const tempData = this.formConfig.row;
        const data = await request.get('/sfa/sfaShippingTaskController/findDetailById', { id: this.formConfig.row.id }).then((res) => (res.success ? res.result : []));
        const productRespVoList = this.getRule('productRespVoList');
        productRespVoList.props = {
          code: this.formConfig.code,
          ...productRespVoList.props,
          value: data.productRespVoList,
          detailData: data,
          formConfig: this.formConfig,
        };
        this.setValue({
          ...data,
          ...tempData,
          photoRespVoList: data.photoRespVos,
          value: data.productRespVoList,
        });
      }
    },
  },

};
